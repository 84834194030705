import {Injectable} from '@angular/core';
import {map, Observable} from 'rxjs';
import {Controller} from 'src/app/enums/backend/core/controller.enum';
import {Icd10ControllerEndpoint} from 'src/app/enums/backend/core/endpoints/icd-10-controller-endpoint.enum';
import {Page} from 'src/app/interfaces/backend/core/page.interface';
import {Pageable} from 'src/app/interfaces/backend/core/pageable.interface';
import {DicIcd10Dto} from 'src/app/interfaces/backend/dto/dic-icd-10-dto.interface';
import {Payload} from 'src/app/interfaces/core/payload.interface';
import {EndpointsService} from 'src/app/services/core/endpoints.service';

@Injectable({providedIn: 'root'})
export class Icd10ControllerService {
    private controller: Controller = Controller.ICD_10;

    public constructor(
        private endpointsService: EndpointsService
    ) {
    }

    public getIcd10Codes(pageable: Pageable, filter?: string): Observable<Page<DicIcd10Dto>> {
        const payload: Payload = {queryParams: {...pageable, filter}};
        return this.endpointsService.send(this.controller, Icd10ControllerEndpoint.GET_ICD_10_CODES, payload)
                   .pipe(map((data) => ({
                           content: data.Data.dicIcd10DtoSet,
                           totalElements: data.Data.allRecords
                       }))
                   );
    }
}