import {Injectable} from '@angular/core';
import {Observable, tap} from 'rxjs';
import {Page} from 'src/app/interfaces/backend/core/page.interface';
import {Pageable} from 'src/app/interfaces/backend/core/pageable.interface';
import {DicIcd10Dto} from 'src/app/interfaces/backend/dto/dic-icd-10-dto.interface';
import {Icd10ControllerService} from 'src/app/services/backend/icd-10-controller.service';

@Injectable({providedIn: 'root'})
export class Icd10Facade {
    public dataPage: Page<DicIcd10Dto> | null = null;

    constructor(
        private icd10ControllerService: Icd10ControllerService
    ) {
    }

    public reset() {
        this.dataPage = null;
    }

    public loadData(pageable: Pageable = {}) {
        this.getData(pageable).pipe(
            tap(data => this.dataPage = data)
        ).subscribe();
    }

    public getData(pageable: Pageable = {}, filter?: string): Observable<Page<DicIcd10Dto>> {
        return this.icd10ControllerService.getIcd10Codes(pageable, filter);
    }
}